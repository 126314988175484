import Marquee from 'react-fast-marquee';

interface Props {
  text: string;
  direction?: 'right' | 'left' | undefined;
  onClick?: () => void;
}

export const TextMarquee = ({ text, onClick, direction = 'right' }: Props) => {
  return (
    <Marquee
      gradient={false}
      direction={direction}
      loop={0}
      className="z-0 cursor-pointer bg-white"
    >
      {[
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20,
      ].map((i) => (
        <p
          onClick={onClick}
          key={i}
          className="flex py-1 pr-4 text-sm font-light uppercase text-black"
        >
          {text}
          <span />
        </p>
      ))}
    </Marquee>
  );
};
