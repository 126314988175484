import React from 'react';

import { MarketplaceAPI } from 'revibe-api';
import { useTranslation } from 'i18n/hooks';
import { Button, Input, Drawer, SpacedContainer, cn } from 'revibe-ui';

import { useBoolean, useToast } from 'modules/common/hooks';
import { useForm } from 'react-hook-form';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { fontVariables } from 'ui/fonts/fontVariables';

export const PasswordReset = () => {
  const { tu } = useTranslation('auth');
  const [isDrawerOpen, openDrawer, closeDrawer] = useBoolean();
  const [isEmailSent, setIsEmailSent, setIsEmailNotSent] = useBoolean();

  const { errorToast } = useToast();

  const handleResetRequest = async ({ email }: { email: string }) => {
    const { error } = await MarketplaceAPI.requestPasswordReset(email);
    if (error) {
      errorToast(error);
    } else {
      setIsEmailSent();
    }
  };

  const handleClose = async () => {
    setIsEmailNotSent();
    closeDrawer();
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  return (
    <div>
      <p
        className="cursor-pointer whitespace-nowrap text-xs font-semibold"
        onClick={openDrawer}
      >
        {tu('password-forgot')}
      </p>
      <Drawer
        scrollable
        className={cn('px-6 py-8', fontVariables)}
        onOpenChange={handleClose}
        position="bottom"
        open={isDrawerOpen}
      >
        <form
          className="mx-auto max-w-[512px]"
          onSubmit={handleSubmit(handleResetRequest)}
        >
          {isEmailSent ? (
            <SpacedContainer>
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <EnvelopeIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <p className="text-xl font-medium leading-6 text-gray-900">
                    {tu('password-forgot')}
                  </p>
                  <p className="text-gray-600">
                    {tu('password-forgot-success-email-sent')}
                  </p>
                </div>
              </div>
              <Button
                type="button"
                onClick={handleClose}
                isLoading={isSubmitting}
              >
                {tu('close', 'common')}
              </Button>
            </SpacedContainer>
          ) : (
            <SpacedContainer>
              <div className="flex flex-col items-center space-y-2">
                <p className="text-xl font-medium leading-6 text-gray-900">
                  {tu('password-forgot')}
                </p>
                <p className="text-gray-600">
                  {tu('password-forgot-enter-your-email')}
                </p>
              </div>
              <Input
                {...register('email', { required: true })}
                className="mt-2 w-full"
                placeholder="email@example.com"
                type={'email'}
                error={errors.email?.message}
              />
              <Button isLoading={isSubmitting} type="submit">
                {tu('submit', 'common')}
              </Button>
            </SpacedContainer>
          )}
        </form>
      </Drawer>
    </div>
  );
};
