import React from 'react';

import Svg from './logo.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const LogoIcon = ({ className, onClick }: Props) => (
  <Svg className={className} onClick={onClick} />
);
