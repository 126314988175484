import React from 'react';

import { Drawer } from 'revibe-ui';

import { LoginForm } from './LoginForm';
import { useAuth } from '../hooks/useAuth';
import { fontVariables } from 'ui/fonts/fontVariables';

export const AuthModal = () => {
  const { authModal } = useAuth();

  return (
    <Drawer
      scrollable
      open={authModal.isOpen}
      onOpenChange={authModal.close}
      position="right"
      className={fontVariables}
    >
      <div className="flex h-full w-full items-center p-4">
        <LoginForm onLogin={authModal.close} />
      </div>
    </Drawer>
  );
};
