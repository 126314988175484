import React from 'react';

import Svg from './facebook.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const FacebookIcon = ({ className, onClick }: Props) => (
  <Svg className={className} onClick={onClick} />
);
