import React, { useState } from 'react';

import { useTranslation } from 'i18n/hooks';
import {
  Button,
  Checkbox,
  cn,
  ErrorBanner,
  Label,
  Link,
  Separator,
} from 'revibe-ui';
import { FormControl } from 'revibe-ui';
import { SpacedContainer } from 'revibe-ui';

import { MarketplaceAPI } from 'revibe-api';

import { FBLoginButton } from 'modules/auth/components/FBLoginButton';

import { Input } from 'ui/components/Input';

import { useAuth } from '../hooks/useAuth';
import { PasswordReset } from './PasswordReset';
import { Controller, useForm } from 'react-hook-form';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { PasswordRequirements } from './PasswordRequirements';
import { validatePasswordFormat } from '../utils';
import { I18N_NAMESPACES } from 'i18n';
import { useRouter } from 'next/router';

interface Props {
  onLogin?: () => void;
}

export const LoginForm = ({ onLogin = () => undefined }: Props) => {
  const router = useRouter();
  const referralCode = (router.query.referral as string) || '';
  const { tu, t, language } = useTranslation('auth');
  const { loginWithCredentials, isLoggingIn } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [authFlow, setAuthFlow] = useState<'check' | 'login' | 'signup'>(
    'check'
  );
  const handleLogin = async (email: string, password: string) => {
    await loginWithCredentials(
      {
        email,
        password,
      },
      {
        onError: setError,
        onSuccess: onLogin,
      }
    );
  };

  const handleSignup = async (user: MarketplaceAPI.UserCreation) => {
    const { data, error } = await MarketplaceAPI.createUser(user, language);
    if (data) {
      await loginWithCredentials({
        email: user.email,
        password: user.password,
      });
      await handleLogin(user.email, user.password);
    } else {
      setError(error);
    }
  };

  const {
    handleSubmit: emailSubmit,
    register: emailRegister,
    formState: { errors: emailErrors, isSubmitting: emailIsSubmitting },
    watch: emailWatch,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const email = emailWatch('email');

  const {
    handleSubmit: loginSubmit,
    register: loginRegister,
    formState: { errors: loginErrors },
  } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const {
    handleSubmit: signupSubmit,
    register: signupRegister,
    control: signupControl,
    formState: { errors: signupErrors, isSubmitting: signupIsSubmitting },
  } = useForm({
    defaultValues: {
      password: '',
      password_confirmation: '',
      name: '',
      policy_accepted: true,
      is_subscribed_to_newsletter: false,
      referral_code: referralCode,
    },
  });

  return (
    <SpacedContainer className="w-full">
      <form
        className={cn('w-full', authFlow !== 'check' && 'hidden')}
        onSubmit={emailSubmit(async (values) => {
          const { data, error } = await MarketplaceAPI.checkUserExistance(
            values.email,
            language
          );
          if (data) {
            if (data.exists) {
              setAuthFlow('login');
            } else {
              setAuthFlow('signup');
            }
          } else {
            setError(error);
          }
        })}
      >
        <SpacedContainer className="w-full">
          {error && (
            <ErrorBanner>{tu(error, 'notifications.error')}</ErrorBanner>
          )}
          <SpacedContainer>
            <FormControl label={tu('email')}>
              <Input
                type={'email'}
                {...emailRegister('email', { required: true })}
                error={emailErrors.email && tu('errors.email-required')}
              />
            </FormControl>
            <Button type="submit" isLoading={emailIsSubmitting}>
              {tu('next')}
              <ArrowRightIcon className="ml-2 h-4 w-4 text-white" />
            </Button>
          </SpacedContainer>
        </SpacedContainer>
      </form>

      <SpacedContainer
        className={cn('w-full', authFlow !== 'login' && 'hidden')}
      >
        {error && <ErrorBanner>{tu(error, 'notifications.error')}</ErrorBanner>}
        <form
          className="w-full"
          onSubmit={loginSubmit(async (values) => {
            await handleLogin(email, values.password);
          })}
        >
          <SpacedContainer>
            <FormControl label={tu('email')}>
              <SpacedContainer type="horizontal" className="w-full">
                <Input
                  value={email}
                  disabled
                  containerClassName="w-full flex-1"
                />
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => setAuthFlow('check')}
                >
                  {tu('change')}
                </Button>
              </SpacedContainer>
            </FormControl>
            <FormControl label={tu('password')}>
              <Input
                type="password"
                {...loginRegister('password', { required: true })}
                error={loginErrors.password && tu('errors.password-required')}
              />
            </FormControl>
            <Button type="submit" isLoading={isLoggingIn}>
              {tu('login')}
            </Button>
          </SpacedContainer>
        </form>
        <PasswordReset />
      </SpacedContainer>
      <SpacedContainer
        className={cn('w-full', authFlow !== 'signup' && 'hidden')}
      >
        <form
          className="w-full"
          onSubmit={signupSubmit(async (values) => {
            await handleSignup({ ...values, email });
          })}
        >
          <SpacedContainer>
            <FormControl label={tu('email')}>
              <SpacedContainer type="horizontal" className="w-full">
                <Input
                  value={email}
                  disabled
                  containerClassName="w-full flex-1"
                />
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => setAuthFlow('check')}
                >
                  {tu('change')}
                </Button>
              </SpacedContainer>
            </FormControl>
            <FormControl label={tu('name')}>
              <Input
                error={
                  signupErrors.name && (
                    <div>
                      <p>{tu('errors.name-required')}</p>
                    </div>
                  )
                }
                {...signupRegister('name', {
                  required: true,
                })}
              />
            </FormControl>
            <FormControl label={tu('password')}>
              <Input
                type="password"
                error={
                  signupErrors.password && (
                    <div>
                      <p>{tu('errors.password-required')}</p>
                      <PasswordRequirements />
                    </div>
                  )
                }
                {...signupRegister('password', {
                  required: true,
                  validate: (value) => validatePasswordFormat(value),
                })}
              />
            </FormControl>
            <FormControl
              label={`${tu('referral-code', 'user.wallet')} (${tu(
                'optional',
                I18N_NAMESPACES.COMMON
              )})`}
            >
              <Input {...signupRegister('referral_code')} />
            </FormControl>
            <div className="flex items-center space-x-2">
              <Controller
                name="is_subscribed_to_newsletter"
                control={signupControl}
                render={({ field }) => (
                  <Checkbox
                    className="max-w-lg"
                    id="is_subscribed_to_newsletter"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                )}
              />
              <Label
                htmlFor="is_subscribed_to_newsletter"
                className="text-xs font-light md:text-sm"
              >
                {tu('subscribe_signup', 'user.newsletter')}
              </Label>
            </div>

            <Button type="submit" isLoading={signupIsSubmitting}>
              {tu('signup')}
            </Button>
          </SpacedContainer>
        </form>
        <div className="flex flex-wrap items-center justify-center gap-1">
          <p className="m-0 whitespace-nowrap text-center text-sm text-gray-500 lg:text-left">
            {tu('read-and-accept')}{' '}
          </p>
          <Link
            className="m-0 whitespace-nowrap text-center text-sm lg:text-left"
            to="/terms-of-service"
            target="blank"
          >
            {t('terms-of-service')}
          </Link>
        </div>
      </SpacedContainer>
      <div className="flex w-full items-center gap-2 py-2">
        <Separator className="flex-1" />
        <span className="text-sm text-slate-300">{t('or', 'common')}</span>
        <Separator className="flex-1" />
      </div>

      <FBLoginButton />
    </SpacedContainer>
  );
};
