import { Address } from '../../../types/addresses.types';
import { apiCall } from '../../../utils/apiCall';
import { UserAddress } from '../addresses/addresses.types';
import { User, UserPromotion } from '../users/users.types';
import { CartItem } from './cart.types';

export type Cart = {
  id: number;
  payment_intent_secret: string;
  payment_intent_id: string;
  subtotal: {
    total: number;
    no_promo: number;
  };
  delivery: {
    type: 'standard' | 'express';
    fees: {
      standard: number;
      express: number;
    };
    address: UserAddress | null;
  };
  promo: UserPromotion | null;
  items: CartItem[];
  user: User | null;
  status: 'active' | 'inactive' | 'completed';
};

type ItemMatchData = {
  id: number;
  size: string;
  customization?: string;
  colors?: string;
};

export const getCart = (id: number) =>
  apiCall({
    endpoint: `marketplace/cart/${id}`,
    method: 'GET',
    reshaper: (data: Cart) => data,
  });

export const addItemToCart = async ({
  cartID,
  item,
}: {
  cartID?: number;
  item: ItemMatchData;
}) => {
  let id = cartID;

  if (!id) {
    const { data, error } = await apiCall({
      endpoint: `marketplace/cart`,
      method: 'POST',
      reshaper: (data: Cart) => data,
    });

    if (!data) {
      return {
        data: null,
        error,
      };
    }

    id = data.id;
  }

  return await apiCall({
    endpoint: `marketplace/cart/${id}/items`,
    method: 'POST',
    body: item,
    reshaper: (data: CartItem) => ({
      cartID: id as number,
      item: data,
    }),
  });
};

export const removeItemFromCart = ({
  cartID,
  itemID,
}: {
  cartID: number;
  itemID: number;
}) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/items/${itemID}`,
    method: 'DELETE',
    reshaper: (data: CartItem) => data,
  });

export const updateItemCartInfo = ({
  cartID,
  itemID,
  update,
}: {
  cartID: number;
  itemID: number;
  update: { user_info?: string };
}) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/items/${itemID}`,
    method: 'PUT',
    body: update,
    reshaper: (data: CartItem) => data,
  });

export const addPromoToCart = ({
  cartID,
  promoID,
}: {
  cartID: number;
  promoID: number;
}) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/promo`,
    method: 'POST',
    body: {
      promoID,
    },
    reshaper: (data: UserPromotion) => data,
  });

export const removePromoFromCart = ({ cartID }: { cartID: number }) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/promo`,
    method: 'DELETE',
    reshaper: (data: UserPromotion) => data,
  });

export const updateCartAddress = ({
  cartID,
  address,
}: {
  cartID: number;
  address: Omit<Partial<Address>, 'id'>;
}) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/address`,
    method: 'POST',
    body: address,
    reshaper: (data: { updated: boolean }) => data,
  });

export const updateCartUser = ({
  cartID,
  body,
}: {
  cartID: number;
  body: { id: number };
}) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/user`,
    method: 'POST',
    body,
    reshaper: (data: { updated: boolean }) => data,
  });

export const updateCartDeliveryType = ({
  cartID,
  body,
}: {
  cartID: number;
  body: { type: 'standard' | 'express' };
}) =>
  apiCall({
    endpoint: `marketplace/cart/${cartID}/delivery/type`,
    method: 'POST',
    body,
    reshaper: (data: { updated: boolean }) => data,
  });
