import {
  LoginWithCredentials,
  LoginWithFacebook,
} from '../../../queries/auth.query';
import { apiCall } from '../../../utils/apiCall';
import { AuthResponse } from './auth.types';

export const requestPasswordReset = async (email: string) =>
  apiCall({
    endpoint: `auth/request-password-reset`,
    method: 'POST',
    body: { email, type: 'user' },
    reshaper: (data: any) => data,
  });

export const resetPassword = async (password: string, hash: string) =>
  apiCall({
    endpoint: `auth/password-reset`,
    method: 'POST',
    body: { password, hash, type: 'user' },
    reshaper: (data: any) => data,
  });

export const loginMarketplaceUserWithCredentials = async ({
  email,
  password,
  ...params
}: LoginWithCredentials) =>
  apiCall({
    ...params,
    endpoint: `marketplace/auth/credentials`,
    method: 'POST',
    body: { email, password },
    reshaper: (data: AuthResponse) => data,
  });

export const loginMarketplaceUserWithFacebook = async ({
  email,
  ...params
}: LoginWithFacebook) =>
  apiCall({
    ...params,
    endpoint: `marketplace/auth/facebook`,
    method: 'POST',
    body: { email },
    reshaper: (data: AuthResponse) => data,
  });
