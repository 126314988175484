import { useDispatch, useSelector } from 'react-redux';
import { selectCartID, updateCartID } from 'redux/features/cart';

import { useMutation, useQuery } from '@tanstack/react-query';
import { MarketplaceAPI } from 'revibe-api';
import { useToast } from 'modules/common/hooks';
import { selectCartPanelIsOpen, toggleCartPanel } from 'redux/features/modals';
import { useDataLayer } from 'modules/tracking/hooks/useDataLayer';
import { useAuth } from 'modules/auth/hooks/useAuth';

export const useCart = () => {
  const cartID = useSelector(selectCartID);
  const isOpen = useSelector(selectCartPanelIsOpen);
  // const router = useRouter();
  const { refetchUser, userID } = useAuth();
  const dataLayer = useDataLayer();
  const { errorToast, successToast } = useToast();
  const dispatch = useDispatch();
  const clearCartID = () => dispatch(updateCartID(null));

  /**
   * Get cart
   */
  const {
    data: cart,
    refetch: refetchCart,
    isFetching: isLoadingCart,
  } = useQuery(
    ['Get Cart'],
    async () => {
      if (!cartID) {
        return null;
      }

      const { data, error } = await MarketplaceAPI.getCart(cartID);
      if (data) {
        if (data.status !== 'active') {
          clearCartID();
          return null;
        }
        if (!data.user && userID) {
          updateCartUser({ cartID, body: { id: Number(userID) } });
        }
        return data;
      } else {
        dataLayer.trackException(
          `Error getting the cart: ${error || 'Unknown'}`
        );
        clearCartID();
        return null;
      }
    },
    {
      enabled: cartID !== null,
    }
  );

  /**
   * Update cart user
   */
  const { mutate: updateCartUser } = useMutation(
    ['Update Cart User'],
    MarketplaceAPI.updateCartUser,
    {
      onError: () => {
        dataLayer.trackException(`Error updating the cart user`);
      },
      onSuccess: () => {
        refetchCart();
      },
    }
  );

  /**
   * REMOVE ITEM FROM CART + track GA event + refetch cart
   */
  const { mutate: removeFromCart, isLoading: isRemovingProduct } = useMutation(
    [`Remove product from cart`],
    MarketplaceAPI.removeItemFromCart,
    {
      onSuccess: ({ data: item }) => {
        if (item) {
          dataLayer.trackRemoveFromCart(item);
        }
        successToast('item-removed-from-cart');
        refetchCart();
      },
    }
  );

  /**
   * ADD ITEM TO CART + track GA event + refetch cart
   */
  const { mutate: addToCart, isLoading: isAddingProduct } = useMutation(
    [`Add product to cart`],
    MarketplaceAPI.addItemToCart,
    {
      onSuccess: ({ data, error }) => {
        if (error) {
          dataLayer.trackException(
            `Error adding to cart: ${error || 'Unknown'}`
          );
          errorToast(error);
          return;
        }

        if (data) {
          if (!cartID) {
            dispatch(updateCartID(data.cartID));
          }

          dataLayer.trackAddToCart(data.item);
        }
        successToast('item-added-to-cart');
        refetchCart();
        dispatch(toggleCartPanel(true));
      },
    }
  );

  /**
   * UPDATE ITEM CART INFO + refetch cart
   */
  const { mutate: updateItemCartInfo, isLoading: isUpdatingProductInfo } =
    useMutation(
      [`Update cart product info`],
      MarketplaceAPI.updateItemCartInfo,
      {
        onSuccess: () => {
          refetchCart();
        },
      }
    );

  /**
   * UPDATE CART ADDRESS + track GA event + refetch cart
   */
  const { mutate: updateCartAddress, isLoading: isUpdatingAddress } =
    useMutation(['Update cart address'], MarketplaceAPI.updateCartAddress, {
      onSuccess: async () => {
        await refetchCart();
        cart && dataLayer.trackAddShippingInfo(cart);
      },
    });

  /**
   * UPDATE CART DELIVERY TYPE + track GA event + refetch cart
   */
  const { mutate: updateCartDeliveryType, isLoading: isUpdatingDeliveryType } =
    useMutation(
      ['Update cart delivery type'],
      MarketplaceAPI.updateCartDeliveryType,
      {
        onSuccess: async () => {
          await refetchCart();
          cart && dataLayer.trackAddShippingInfo(cart);
        },
      }
    );

  /**
   * UPDATE CART PROMO + track GA event + refetch cart
   */
  const { mutate: addPromoToCart, isLoading: isAddingPromo } = useMutation(
    ['Add promo code to cart'],
    MarketplaceAPI.addPromoToCart,
    {
      onError: errorToast,
      onSuccess: () => {
        successToast('promo-added-to-cart');
        refetchCart();
        refetchUser();
      },
    }
  );

  /**
   * REMOVE CART PROMO + track GA event + refetch cart
   */
  const { mutate: removePromoFromCart, isLoading: isRemovingPromo } =
    useMutation(
      ['Remove promo code from cart'],
      MarketplaceAPI.removePromoFromCart,
      {
        onError: errorToast,
        onSuccess: () => {
          successToast('promo-removed-from-cart');
          refetchCart();
          refetchUser();
        },
      }
    );

  return {
    cartID,
    cart: cart
      ? {
          ...cart,
          isEmpty: cart.items.length === 0,
          itemsCount: cart.items.reduce((acc, item) => acc + item.amount, 0),
        }
      : null,
    addToCart,
    removeFromCart,
    updateItemCartInfo,
    updateCartAddress,
    updateCartDeliveryType,
    addPromoToCart,
    removePromoFromCart,
    clearCart: () => {
      clearCartID();
      refetchCart();
    },
    cartState: {
      isLoadingCart,
      isAddingProduct,
      isRemovingProduct,
      isUpdatingProductInfo,
      isUpdatingAddress,
      isUpdatingDeliveryType,
      isAddingPromo,
      isRemovingPromo,
    },
    cartModal: {
      isOpen,
      open: () => {
        cart && dataLayer.trackViewCart(cart);
        dispatch(toggleCartPanel(true));
      },
      close: () => dispatch(toggleCartPanel(false)),
    },
  };
};
