import React from 'react';

import { useTranslation } from 'i18n/hooks';

export const PasswordRequirements = () => {
  const { tu, t } = useTranslation('auth');

  return (
    <p className="mt-1 mb-0 text-xs text-gray-500">
      {tu('password-requirement1')}, {t('password-requirement2')},{' '}
      {t('password-requirement3')}
    </p>
  );
};
