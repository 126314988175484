import React from 'react';

import { useRouter } from 'next/router';

import { useTranslation } from 'i18n/hooks';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Button } from 'revibe-ui';
import { SpacedContainer } from 'revibe-ui';

import * as Sentry from '@sentry/nextjs';

import { getIsDev } from 'modules/common/utils/env';

import { Layout } from './Layout';

type Props = {
  children: React.ReactNode;
};

export const ErrorPage = ({ error }: any) => {
  const router = useRouter();
  const { tu } = useTranslation('titles');
  if (!getIsDev()) {
    Sentry.captureException(error);
  }
  return (
    <Layout centered>
      <SpacedContainer centered>
        <p>{tu('error')}:</p>
        <pre>{error?.message}</pre>
        <Button onClick={() => router.reload()}>Try again</Button>
      </SpacedContainer>
    </Layout>
  );
};

export const ErrorBoundary = ({ children }: Props) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorPage}>
      {children}
    </ReactErrorBoundary>
  );
};
