import React from 'react';

import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';

import { FacebookIcon } from 'ui/icons/FacebookIcon';
import { useAuth } from '../hooks/useAuth';

export const FBLoginButton = () => {
  const { tu } = useTranslation('auth');

  const { loginWithFacebook, isLoggingIn } = useAuth();

  return (
    <Button
      variant="subtle"
      onClick={() => loginWithFacebook()}
      type="button"
      isLoading={isLoggingIn}
    >
      <FacebookIcon className="mr-2 h-6 w-6 stroke-black" /> {tu('login-fb')}
    </Button>
  );
};
