import passwordValidator from 'password-validator';

const schema = new passwordValidator();
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(30) // Maximum length 100
  .has()
  .letters() // Must have letter
  .has()
  .digits() // Must have numbers
  .has()
  .not()
  .spaces(); // Should not have spaces

export const validatePasswordFormat = (password: string) =>
  Boolean(schema.validate(password, { details: false }));
export const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validatePassword = (
  password: string,
  password_confirmation: string
) => {
  const isPasswordValid = validatePasswordFormat(password);
  const arePasswordsMatching = password === password_confirmation;
  const errors: string[] = [];
  if (!isPasswordValid) {
    errors.push('invalid-password');
  }
  if (!arePasswordsMatching) {
    errors.push('passwords-mismatch');
  }
  return errors;
};

export const FEATURE_FLAGS = {
  CUSTOM_FIELDS: 'Custom_Fields',
};
