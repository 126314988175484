import React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import {
  I18N_NAMESPACES,
  SUPPORTED_LOCALES,
  SUPPORTED_LOCALES_STRINGS,
} from 'i18n';
import { useTranslation } from 'i18n/hooks';

import NoSSR from 'modules/common/components/NoSSR';

type Props = {
  title: string;
  description?: string | undefined | null;
  keywords?: string | undefined;
  children: React.ReactNode;
  shouldPostfixTitle?: boolean;
  noIndex?: boolean;
  noSSR?: boolean;
};

export const Page = ({
  title,
  description,
  noIndex,
  children,
  shouldPostfixTitle = true,
  noSSR = false,
  keywords = '',
}: Props) => {
  const { tu } = useTranslation();
  const router = useRouter();
  const localeUrls = SUPPORTED_LOCALES_STRINGS.map((lang) => (
    <link
      key={lang}
      rel="alternate"
      href={`https://www.revibe-upcycling.com${
        lang === SUPPORTED_LOCALES.EN ? '' : '/' + lang
      }${router.asPath === '/' ? '' : router.asPath}`}
      hrefLang={lang}
    />
  ));
  const meta = [
    {
      name: 'description',
      content: description || tu('home.description', 'seo'),
    },
    {
      name: 'og:description',
      content: description || tu('home.description', 'seo'),
    },
    {
      name: 'keywords',
      content: `${tu('home.keywords', 'seo')},${keywords}`,
    },
  ];
  if (noIndex) {
    meta.push({
      name: 'robots',
      content: 'noindex',
    });
  }

  const renderContent = () => (
    <>
      <Head>
        <title>
          {shouldPostfixTitle
            ? `${title} | ${tu('home', I18N_NAMESPACES.PAGE_TITLES)}`
            : title}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {localeUrls}
        {meta.map((metaEntry) => (
          <meta
            key={metaEntry.name}
            name={metaEntry.name}
            content={metaEntry.content}
          />
        ))}
      </Head>
      {children}
    </>
  );

  if (noSSR) {
    return <NoSSR suppressHydrationWarning>{renderContent()}</NoSSR>;
  }

  return renderContent();
};
