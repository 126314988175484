import React from 'react';

import { useRouter } from 'next/router';

import {
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { SpacedContainer } from 'revibe-ui';
import { BLOG_ROUTES, USER_ROUTES } from 'routes';

import useScrollPosition from '@react-hook/window-scroll';

import { useAuth } from 'modules/auth/hooks/useAuth';

import { Link } from 'ui/components/Link';
import { LogoIcon } from 'ui/icons/LogoIcon';

import { useCart } from 'modules/checkout/hooks/useCart';
import { I18N_NAMESPACES } from 'i18n';

type Props = {
  isLogoAlwaysVisible?: boolean;
  hideLogo?: boolean;
  toggleCategoriesMenu: () => void;
};

export const NavBar = ({
  toggleCategoriesMenu,
  isLogoAlwaysVisible,
  hideLogo = false,
}: Props) => {
  const { cartModal, cart } = useCart();
  const { tu } = useTranslation();
  const router = useRouter();
  const scrollY = useScrollPosition(60);
  const { isLoggedIn, authModal, logout } = useAuth();

  return (
    <nav className="font-formula relative z-10 box-border flex w-[100vw] items-center justify-between border-y border-black bg-white px-4 py-2 uppercase">
      <SpacedContainer
        type="horizontal"
        centered
        className="-mb-1.5 overflow-hidden"
      >
        <p
          className="cursor-pointer text-2xl text-black md:text-3xl"
          onClick={toggleCategoriesMenu}
        >
          menu
        </p>
        <Link
          className="hidden cursor-pointer text-2xl text-black hover:text-black md:inline md:text-3xl"
          hasDecoration={false}
          to={BLOG_ROUTES.HOME}
        >
          magazine
        </Link>
      </SpacedContainer>
      <SpacedContainer
        type="horizontal"
        centered
        className="-mb-1.5 overflow-hidden"
      >
        {isLoggedIn ? (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <p className="hidden cursor-pointer text-2xl uppercase text-black md:inline md:text-3xl">
                {tu('user.account')}
              </p>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="rounded-none border border-black p-0 shadow-none">
              <DropdownMenuItem>
                <Link to={USER_ROUTES.ACCOUNT}>
                  {tu('preferences', I18N_NAMESPACES.USER)}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link to={USER_ROUTES.ORDERS}>
                  {tu('orders.heading', I18N_NAMESPACES.USER)}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link to={USER_ROUTES.WISHLIST}>
                  {tu('wishlist.heading', I18N_NAMESPACES.USER)}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link to={USER_ROUTES.WALLET}>
                  {tu('wallet.heading', I18N_NAMESPACES.USER)}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => logout()}
                className="cursor-pointer text-red-800"
              >
                {tu('logout', I18N_NAMESPACES.USER)}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <p
            className="hidden cursor-pointer text-2xl text-black md:inline md:text-3xl"
            onClick={authModal.open}
          >
            {tu('login', 'auth')}
          </p>
        )}
        <p
          className="cursor-pointer text-2xl text-black md:text-3xl "
          onClick={cartModal.open}
        >
          {tu('cart', 'shop')} {cart ? `(${cart.itemsCount})` : '(0)'}
        </p>
      </SpacedContainer>
      {!hideLogo && (
        <div
          className={cn(
            'absolute left-[calc(50%-3rem)] h-full w-24 cursor-pointer fill-black transition-opacity md:left-[calc(50%-3.5rem)] md:w-28',
            isLogoAlwaysVisible || scrollY > 96 ? 'opacity-100' : 'opacity-0'
          )}
        >
          <LogoIcon
            className="h-full w-full fill-black text-black"
            onClick={() => router.push('/')}
          />
        </div>
      )}
    </nav>
  );
};
