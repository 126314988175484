import React, { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';

import useScrollPosition from '@react-hook/window-scroll';

import { AuthModal } from 'modules/auth/components/AuthModal';
import { ErrorBoundary } from 'modules/common/components/ErrorBoundary';
import { Footer } from 'modules/common/components/Footer';
import { NavBar } from 'modules/common/components/NavBar';

import { Page } from '../Page';
import { TextMarquee } from '../TextMarquee';
import styles from './ShopPage.module.css';

const NextNProgress = dynamic(() => import('nextjs-progressbar'));
const CartPanel = dynamic<Record<string, never>>(() =>
  import('modules/checkout/components/CartPanel').then((mod) => mod.CartPanel)
);
const CategoriesPanel = dynamic<{
  isOpen: boolean;
  onClose: () => void;
}>(() =>
  import('modules/catalog/components/CategoriesPanel').then(
    (mod) => mod.CategoriesPanel
  )
);

type Props = {
  title: string;
  description?: string | undefined | null;
  keywords?: string | undefined;
  children: React.ReactNode;
  shouldPostfixTitle?: boolean;
  isLogoAlwaysVisible?: boolean;
  noIndex?: boolean;
  hideLogo?: boolean;
  isShopPage?: boolean;
  noSSR?: boolean;
  noFooterTopSpace?: boolean;
};

export const ShopPage = ({
  title,
  description,
  noIndex,
  children,
  shouldPostfixTitle = true,
  isLogoAlwaysVisible = true,
  hideLogo = false,
  isShopPage = false,
  noFooterTopSpace = false,
  noSSR = false,
  keywords = '',
}: Props) => {
  const { tu } = useTranslation();
  const [isCategoriesPanelOpen, setIsCategoriesPanelOpen] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChanged = () => {
      setIsCategoriesPanelOpen(false);
    };
    router.events.on('routeChangeStart', handleRouteChanged);
    return () => {
      router.events.off('routeChangeStart', handleRouteChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  const handleToggleCategoriesPanel = () => {
    setIsCategoriesPanelOpen(!isCategoriesPanelOpen);
  };
  const scrollY = useScrollPosition(60);

  return (
    <Page
      noIndex={noIndex}
      title={title}
      noSSR={noSSR}
      keywords={keywords}
      shouldPostfixTitle={shouldPostfixTitle}
      description={description}
    >
      <div
        className="relative h-full w-[100vw]"
        suppressHydrationWarning={true}
      >
        <div
          className={
            isShopPage ? 'h-[4.5rem] md:h-[4.75rem]' : 'h-[2.75rem] md:h-[3rem]'
          }
        />
        <NextNProgress
          color="var(--black)"
          startPosition={0.3}
          stopDelayMs={200}
          height={4}
          showOnShallow={true}
          options={{ showSpinner: false }}
        />
        <ErrorBoundary>
          <main>{children}</main>
        </ErrorBoundary>
        <Footer noTopSpace={noFooterTopSpace} />
        <CategoriesPanel
          onClose={handleToggleCategoriesPanel}
          isOpen={isCategoriesPanelOpen}
        />
        <CartPanel />
        <AuthModal />
        <div
          className={cn('fixed top-0', {
            [styles.fixedNavsScrolled]: isShopPage && scrollY > 85,
          })}
        >
          {isShopPage ? (
            <TextMarquee text={tu('free-delivery-banner', 'shop')} />
          ) : null}
          <NavBar
            toggleCategoriesMenu={handleToggleCategoriesPanel}
            hideLogo={hideLogo}
            isLogoAlwaysVisible={isLogoAlwaysVisible}
          />
        </div>
      </div>
    </Page>
  );
};
